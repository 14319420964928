import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import axios from "axios";

import Swal from "sweetalert2";

import {
  settingsTypeUrl,
  partnerUrl,
  distributeUrl,
  memberUrl,
  logoUrl,
  imgLogoUrl,
} from "../../utils/baseUrl";

import { IoTrashOutline, IoCloseSharp } from "react-icons/io5";
import {
  HiOutlineBanknotes,
  HiMiniPencilSquare,
  HiMiniPlus,
} from "react-icons/hi2";

import { FiSave } from "react-icons/fi";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { HiOutlinePlus } from "react-icons/hi";
import { IoImagesSharp } from "react-icons/io5";
// import Swal from "sweetalert2";

const ViewMemberWithCompany = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [memberData, setMemberData] = useState([]);
  const [userId, setUserId] = useState("");
  const [txtSearch, setTxtSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [datas, setDatas] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [companyInfoData, setCompanyInfoData] = useState([]);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [selectedFile, setSectedFile] = useState(null);

  const [laborlogos, setLaborlogos] = useState([]);
  const [skills, setSkills] = useState([]);

  const [laborlogoID, setLaborlogoID] = useState("");
  const [laborlogoPhoto, setLaborlogoPhoto] = useState("");
  const [companyID, setCompanyID] = useState("");
  const [skillID, setSkillID] = useState("");
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [discription, setDiscription] = useState("");
  const [open, setOpen] = useState(false);

  const onOpenModal = (comID) => {
    // alert(comID);
    setCompanyID(comID);
    setOpen(true);
  };
  const onCloseModal = () => setOpen(false);

  function readData(id) {
    axios
      .get(`${logoUrl}readcompanybymemberid.php/${id}`)
      .then(function (response) {
        console.log("COMPANY: ", response.data);
        setDatas(response.data);
        /*         setFirstnameEdit(response.data?.firstname);
        setLastnameEdit(response.data?.lastname);
        setPhoneEdit(response.data?.phone);
        setDiscription(response.data?.discription); */
        /*          setPasswordEdit(response.data?.password); */

        // const [skillID, setSkillID] = useState("");
        // const [title, setTitle] = useState("");
        // const [url, setUrl] = useState("");
        // const [discription, setDiscription] = useState("");

        // const [skillID, setSkillID] = useState("");
        // const [title, setTitle] = useState("");
        // const [url, setUrl] = useState("");
        // const [discription, setDiscription] = useState("");
        // setPltype(response.data?.pltype);
        // const cb = response.data?.isLabor == 1 ? true : false;
        // setEmergency();
        // setIsChecked(cb);
      });
  }

  function readSkill() {
    axios.get(`${logoUrl}viewskill.php`).then(function (response) {
      console.log(response.data);
      setSkills(response.data);
      //userID,laborlogoID,memberID,skillID,title,photo,discription,url
      setSkillID(response.data?.skillID);
      setTitle(response.data?.title);
      setUrl(response.data?.url);
      // setEmailEdit(response.data?.email);
      setDiscription(response.data?.discription);
      /*          setPasswordEdit(response.data?.password); */

      // const [skillID, setSkillID] = useState("");
      // const [title, setTitle] = useState("");
      // const [url, setUrl] = useState("");
      // const [discription, setDiscription] = useState("");
    });
  }
  /*   window.localStorage.setItem("logoID", response.data.user.logoID);
    window.localStorage.setItem("logoPhoto", response.data.user.logoPhoto); */
  useEffect(() => {
    var memid = localStorage.getItem("id");
    var lbid = localStorage.getItem("logoID");
    var lbPhoto = localStorage.getItem("logoPhoto");
    setUserId(memid);
    setLaborlogoID(lbid);
    setLaborlogoPhoto(lbPhoto);
    readData(id);
    readSkill();
  }, []);
  /*   const [laborlogoID, setLaborlogoID] = useState("");
    const [laborlogoPhoto, setLaborlogoPhoto] = useState(""); */
  // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };

  const handleOnSubmit = (data) => {
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("laborlogoID", laborlogoID);
    formData.append("title", title);
    formData.append("photourl", laborlogoPhoto);
    formData.append("url", url);
    formData.append("discription", discription);
    formData.append("userID", userId);
    formData.append("companyID", companyID);
    formData.append("skillID", skillID);
    setLoading(true);
    //laborlogo id,title,photo,discription,url,created_at
    axios
      .post(`${logoUrl}addlaborcompanylogo.php`, formData)
      .then(function (response) {
        console.log(response.data);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "บันทึกข้อมูลเรียบร้อยแล้ว",
          showConfirmButton: false,
          timer: 1500,
        });
        // reset();
        // notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
        setLoading(false); // Stop loading
        //locations
      });
  };

  /*   function searchCompany() {
      setIsLoading(true);
  
      axios
        .post(`${logoUrl}searchmember.php`, {
          searchText: txtSearch,
        })
        .then(function (response) {
          console.log("Search---------");
          const data = response.data;
          console.log("Type: ", typeof data);
          console.log(response?.data);
          console.log("---------Search");
          setMemberData(response?.data);
          setIsLoading(false);
        });
    } */

  /*   useEffect(() => {
      var user = localStorage.getItem("id");
      if (user != null) {
        setUserId(user);
      }
    }, [txtSearch]); */

  /*   useEffect(() => {
    // searchCompany(searchItem);
    readData(id);
  }, []); */
  return (
    <>
      <div className="flex flex-col gap-10">
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="relative z-20 p-4">
            <div className="flex items-center justify-center gap-1 md:p-4 rounded-md w-full">
              <div className="flex gap-4 w-full">
                {/* <span>ระบุวันที่:{checkinData} </span> */}

                {/*                 <input
                  type="text"
                  value={txtSearch}
                  onChange={(e) => setTxtSearch(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                /> */}
                {/*                 <input
                  type="text"
                  className="w-full mb-2 border py-2 px-3 text-grey-darkest"
                  value={searchItem}
                  onChange={handleInputChange}
                  placeholder={`ค้นหาสมาชิก`}
                /> */}

                {/*                 <button
                  className="flex w-[150px] py-3 px-5 justify-center rounded bg-primary p-1 font-medium text-gray"
                  type="button"
                  //   onClick={(e) => searchCompany(e)}
                  onClick={searchCompany}
                >
                  ค้นหา
                </button> */}
              </div>
            </div>
          </div>
          {/* ----------  */}
          <div className="max-w-full overflow-x-auto">
            {/* --------  */}
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-2 text-left dark:bg-meta-4">
                  <th className="min-w-[100px] w-full font-medium text-black dark:text-white">
                    ร้านค้า
                  </th>
                  <th className="min-w-[200px] font-medium text-black dark:text-white">
                    โลโก้
                  </th>
                  <th className="min-w-[200px] font-medium text-black dark:text-white">
                    รายละเอียด
                  </th>
                  <th className="min-w-[200px] font-medium text-black dark:text-white">
                    ที่อยู่
                  </th>
                  <th className="min-w-[100px] font-medium text-black dark:text-white">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {datas.map((item, index) => (
                  <tr key={index}>
                    <td className="border-b border-[#eee] dark:border-strokedark">
                      <h5 className="font-medium text-black dark:text-white">
                        {item?.companyName}
                      </h5>
                      {/*   {item?.id}#                      <p className="text-sm"> {item?.address}</p>
                  <p className="text-sm"> {item?.phone}</p> */}
                    </td>
                    <td className="border-b border-[#eee] dark:border-strokedark">
                      <div className="flex gap-4">
                        {item.logo1 !== null ? (
                          <img
                            src={`${imgLogoUrl}${item.logo1}`}
                            alt={``}
                            className="rounded-lg m-1 w-[40px] h-[40px]"
                          />
                        ) : null}
                        {item.logo2 !== null ? (
                          <img
                            src={`${imgLogoUrl}${item.logo2}`}
                            alt={``}
                            className="rounded-lg m-1 w-[40px] h-[40px]"
                          />
                        ) : null}
                        {item.logo3 !== null ? (
                          <img
                            src={`${imgLogoUrl}${item.logo3}`}
                            alt={``}
                            className="rounded-lg m-1 w-[40px] h-[40px]"
                          />
                        ) : null}
                        {item.logo4 !== null ? (
                          <img
                            src={`${imgLogoUrl}${item.logo4}`}
                            alt={``}
                            className="rounded-lg m-1 w-[40px] h-[40px]"
                          />
                        ) : null}
                      </div>
                    </td>
                    <td className="border-b border-[#eee] dark:border-strokedark">
                      <p>{item?.companyDetail}</p>
                    </td>
                    <td className="border-b border-[#eee] dark:border-strokedark">
                      {item?.companycontact}
                    </td>
                    <td className="border-b border-[#eee] dark:border-strokedark">
                      <div className="flex items-center space-x-3.5">
                        {/*                         <button
                          onClick={() => deleteData(item?.id)}
                          className="hover:text-primary"
                        >
                          <IoTrashOutline size={22} />
                        </button> 
                         className="flex w-[200px] justify-center rounded bg-primary p-3 font-medium text-gray"
                        */}
                        <button
                          onClick={() => onOpenModal(item?.id)}
                          className="cursor-pointer flex w-[120px] justify-center rounded bg-primary p-3 font-medium text-gray"
                          //   className="cursor-pointer w-[100px] py-1 bg-sky-500 text-white rounded-full flex justify-center items-center "
                        >
                          กำหนดโลโก้
                        </button>
                        {/*                         <Link
                          className="flex w-[110px] justify-center rounded bg-primary py-2 font-medium text-gray"
                          to={`/viewmemberwithcompany/addlogomember/${item?.id}`}
                        >
                          กำหนดโลโก้
                        </Link> */}
                        {/*                         <Link
                          className="flex w-[110px] justify-center rounded bg-primary py-2 font-medium text-gray"
                          to={`editlogomember/${item?.id}`}
                        >
                          แก้ไข
                        </Link> */}
                        {/*                         <Link to={`addsalary/${item?.id}`} className="">
                      บันทึกเงินเดือน
                    </Link> */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* --------  */}
          </div>
          {/* ----------  */}
        </div>
      </div>
      <div>
        <Modal open={open} onClose={onCloseModal} center>
          <h2 className="text-lg  w-full mb-4 font-medium text-[#504f4f]  flex justify-center items-center">
            กำหนดโลโก้ให้สมาชิก
          </h2>
          <div className="mb-2">
            <label>ระดับทักษะ</label>
            <select
              value={skillID}
              onChange={(e) => setSkillID(e.target.value)}
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            >
              <option value=""></option>
              {/* <option value="0">มองเห็นทั้งหมด</option> */}
              {skills.map((item, index) => (
                <option key={index} value={`${item.id}`}>
                  {item.title}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-2 flex-1">
            <label>คำอธิบาย</label>
            <input
              type="text"
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            />
          </div>
          <div className="mb-2 flex-1">
            <label>Url Web site</label>
            <input
              type="url"
              name="url"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            />
          </div>
          <div className="mb-2 flex-1">
            <label>รายละเอียด ที่เกี่ยวข้อง(ถ้ามี)</label>
            <input
              type="text"
              name="discription"
              value={discription}
              onChange={(e) => setDiscription(e.target.value)}
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            />
          </div>
          <div className="my-2">
            <label>รูปภาพประกอบที่เกี่ยวข้อง(ถ้ามี)</label>
            <div className="flex flex-col items-start">
              <input type="file" onChange={handleOnChange} />
            </div>
          </div>
          {/* ----  handleOnSubmit*/}

          {/* mpackage id,title,shortdetail,longdetail,price,period,unit,created_at */}
          <div className="flex justify-end">
            <button
              onClick={handleOnSubmit}
              disabled={isLoading}
              type="button"
              className="flex w-[200px] justify-center rounded bg-primary p-3 font-medium text-gray"
            >
              {isLoading ? <>กรุณารอซักครู่..</> : <>บันทึก</>}
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ViewMemberWithCompany;
