import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  partnerUrl,
  distributeUrl,
  memberUrl,
  imgLogoUrl,
  logoUrl,
} from "../../utils/baseUrl";

import axios from "axios";
import Swal from "sweetalert2";

const EditMemberLogo = () => {
  const { id } = useParams();
  /*   const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      laborlogoID: "",
      title: "",
      url: "",
      discription: "-",
    },
  }); */
  //laborlogo id,title,photo,discription,url,created_at
  //laborskill id,userID,laborlogoID,title,photo,discription,url,created_at
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSectedFile] = useState(null);
  const [datas, setDatas] = useState({});
  const [skills, setSkills] = useState([]);
  const [laborlogos, setLaborlogos] = useState([]);
  const [userId, setUserId] = useState("");
  const [laborlogoID, setLaborlogoID] = useState("");
  const [skillID, setSkillID] = useState("");
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [discription, setDiscription] = useState("");
  const [photo, setPhoto] = useState("");
  // const [userId, setUserId] = useState("");
  const [laborlogoPhoto, setLaborlogoPhoto] = useState("");
  // laborlogo id,title,photo,discription,url,created_at
  // laborskill id,userID,laborlogoID,title,photo,discription,url,created_at
  function readData(id) {
    //readskilllogobyid.php
    axios
      .get(`${logoUrl}readskilllogobyid.php/${id}`)
      .then(function (response) {
        console.log(response.data);
        setDatas(response.data);
        setLaborlogoID(response.data?.laborlogoID);
        setSkillID(response.data?.skillID);
        setTitle(response.data?.title);
        setUrl(response.data?.url);
        setDiscription(response.data?.discription);
        setPhoto(response.data?.photo);
        // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
      });
  }
  // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
  /*   function readLaborlogo() {
    axios.get(`${logoUrl}readlaborlogo.php`).then(function (response) {
      console.log(response.data);
      setLaborlogos(response.data);
    });
  } */
  function readSkill() {
    axios.get(`${logoUrl}viewskill.php`).then(function (response) {
      console.log(response.data);
      setSkills(response.data);
    });
  }
  useEffect(() => {
    var uid = localStorage.getItem("id");
    // var lbid = localStorage.getItem("logoID");
    var lbPhoto = localStorage.getItem("logoPhoto");
    setUserId(uid);
    setLaborlogoPhoto(lbPhoto);
    // readLaborlogo();
    readSkill();
    readData(id);
  }, []);
  // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("idEdit", datas.id);
    formData.append("laborlogoID", laborlogoID);
    formData.append("title", title);
    formData.append("photourl", laborlogoPhoto);
    formData.append("url", url);
    formData.append("discription", discription);
    formData.append("userID", userId);
    formData.append("memberID", id);
    formData.append("skillID", skillID);
    setLoading(true);
    //laborlogo id,title,photo,discription,url,created_at
    //updateskilllaborlogo.php
    axios
      .post(`${logoUrl}updateskilllaborlogo.php`, formData)
      .then(function (response) {
        console.log(response.data);
        readData(id);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        // reset();
        // notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
        setLoading(false); // Stop loading
        //locations
      });
  };

  console.log("Skill: ", datas);
  console.log("ID: ", laborlogoID);
  return (
    <>
      <div className="w-full mb-8">
        {photo != null ? (
          <img
            src={`${imgLogoUrl}${photo}`}
            className="rounded-lg w-1/2 h-[300px] object-contain"
          />
        ) : null}
      </div>
      <form onSubmit={handleOnSubmit}>
        {/*             <div className="mb-2">
              <label>โลโก้</label>
              <select
                value={laborlogoID}
                onChange={(e) => setLaborlogoID(e.target.value)}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              >
                {laborlogos.map((item, index) => (
                  <option
                    key={index}
                    value={`${item.id}/${item.photo}/${item.url}`}
                  >
                    {item.title}
                  </option>
                ))}
              </select>
            </div> */}
        <div className="mb-2">
          <label>ระดับทักษะ</label>
          <select
            value={skillID}
            onChange={(e) => setSkillID(e.target.value)}
            className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
          >
            {/* <option value=""></option> */}
            {/* <option value="0">มองเห็นทั้งหมด</option> */}
            {skills.map((item, index) => (
              <option key={index} value={`${item.id}`}>
                {item.title}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-2">
          <label>คำอธิบาย</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
          />
        </div>
        <div className="mb-2">
          <label>Url Web site</label>
          <input
            type="url"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
          />
        </div>
        <div className="mb-2">
          <label>รายละเอียด ที่เกี่ยวข้อง(ถ้ามี)</label>
          <input
            type="text"
            value={discription}
            onChange={(e) => setDiscription(e.target.value)}
            className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
          />
        </div>
        <div className="my-2">
          <label>รูปภาพประกอบที่เกี่ยวข้อง(ถ้ามี)</label>
          <div className="flex flex-col items-start">
            <input type="file" onChange={handleOnChange} />
          </div>
        </div>
        <div className="flex justify-end">
          <button
            disabled={loading}
            type="submit"
            className="flex w-[200px] justify-center rounded bg-primary p-3 font-medium text-gray"
          >
            {loading ? <>กรุณารอซักครู่..</> : <>บันทึก</>}
          </button>
        </div>
      </form>
    </>
  );
};

export default EditMemberLogo;
