import React from "react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import DefaultLayout from "./layout/DefaultLayout";

import {
  Home,
  SignIn,
  Register,
  Member,
  Contact,
  Logo,
  Policy,
  ChangePwd,
  SalePage,
  Skill,
  SearchMember,
  AdvertUnit,
  ShowMember,
  BankAccount,
  HistrorySkill,
} from "./pages";
import { AddMember, EditMember, ViewMember } from "./components/member";
import { AddLogo, EditLogo, ViewLogo } from "./components/logo";
import {
  AddMemberLogo,
  EditMemberLogo,
  ViewMemberLogo,
  ViewMemberLogoCompany,
} from "./components/memberlogo";

import { AddSkill, EditSkill, ViewSkill } from "./components/skill";
import {
  SearchAllmember,
  AddLogoMember,
  UpdateLogoMember,
  ViewMemberWithCompany,
} from "./components/searchmember";
import {
  AddSalepage,
  ViewSalepage,
  PreviewSalepage,
  CreateSalepage,
  PreviewOneSalepage,
  EditImg,
  EditSalepage,
} from "./components/salepage";
import { AddUnit, EditUnit, ViewUnit } from "./components/advert";
import {
  AddHistrory,
  EditHistrory,
  ViewHistrory,
  ViewProfile,
} from "./components/histrory";
const Layout = () => {
  return (
    <DefaultLayout>
      <Outlet />
    </DefaultLayout>
  );
};

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/member",
        element: <Member />, //AddMember, EditMember, Viewmember
        children: [
          {
            path: "",
            element: <ViewMember />,
          },
          {
            path: "addmember",
            element: <AddMember />,
          },
          {
            path: "editmember/:id",
            element: <EditMember />,
          },
        ],
      },
      {
        path: "/register",
        element: <Register />, //AddMemberLogo, EditMemberLogo, ViewMemberLogo
        children: [
          {
            path: "",
            element: <ViewMemberLogo />,
          },
          {
            path: "addmemberlogo/:id",
            element: <AddMemberLogo />,
          },
          {
            path: "viewmemberlogocompany/:id",
            element: <ViewMemberLogoCompany />,
          },
          {
            path: "editmemberlogo/:id",
            element: <EditMemberLogo />,
          },
        ],
      },
      {
        path: "/histroryskill",
        element: <HistrorySkill />, //AddHistrory, EditHistrory, ViewHistrory
        children: [
          {
            path: "",
            element: <ViewHistrory />,
          },
          {
            path: "addhistroryskill/:id",
            element: <AddHistrory />,
          },
          {
            path: "edithistroryskill/:id",
            element: <EditHistrory />,
          },
          {
            path: "profilehistroryskill/:id",
            element: <ViewProfile />,
          },
        ],
      },
      {
        path: "/search",
        element: <SearchMember />, //SearchAllmember, AddLogoMember, UpdateLogoMember
        children: [
          {
            path: "",
            element: <SearchAllmember />,
          },
          {
            path: "viewmemberwithcompany/:id",
            element: <ViewMemberWithCompany />,
          },
          {
            path: "addlogomember/:id",
            element: <AddLogoMember />,
          },
          {
            path: "editlogomember/:id",
            element: <UpdateLogoMember />,
          },
        ],
      },
      {
        path: "/logo",
        element: <Logo />, //AddLogo, EditLogo, ViewLogo
        children: [
          {
            path: "",
            element: <ViewLogo />,
          },
          {
            path: "addlogo",
            element: <AddLogo />,
          },
          {
            path: "editlogo/:id",
            element: <EditLogo />,
          },
        ],
      },
      {
        path: "/bankaccount",
        element: <BankAccount />,
      },
      {
        path: "/showmembers",
        element: <ShowMember />,
      },
      {
        path: "/salepage",
        element: <SalePage />, //AddSalepage, ViewSalepage, PreviewSalepage
        children: [
          {
            path: "",
            element: <ViewSalepage />,
          },
          {
            path: "addsalepage",
            element: <AddSalepage />,
          },
          {
            path: "createsalepage",
            element: <CreateSalepage />,
          },
          {
            path: "previewsalepage/:id",
            element: <PreviewSalepage />,
          },
          {
            path: "previewonesalepage/:id",
            element: <PreviewOneSalepage />,
          },
          {
            path: "editsalepage/:id",
            element: <EditSalepage />,
          },
          {
            path: "editimg/:id",
            element: <EditImg />,
          },
        ],
      },
      {
        path: "/changepwd",
        element: <ChangePwd />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/advertunit",
        element: <AdvertUnit />, //AddUnit, EditUnit, ViewUnit
        children: [
          {
            path: "",
            element: <ViewUnit />,
          },
          {
            path: "addunit",
            element: <AddUnit />,
          },
          {
            path: "editunit/:id",
            element: <EditUnit />,
          },
        ],
      },
      {
        path: "/skills",
        element: <Skill />, //AddSkill, EditSkill, ViewSkill
        children: [
          {
            path: "",
            element: <ViewSkill />,
          },
          {
            path: "addskill",
            element: <AddSkill />,
          },
          {
            path: "editskill/:id",
            element: <EditSkill />,
          },
        ],
      },
    ],
  },
  {
    path: "signin",
    element: <SignIn />,
  },
  {
    path: "/policy",
    element: <Policy />,
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
