import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  partnerUrl,
  distributeUrl,
  memberUrl,
  imgLogoUrl,
  logoUrl,
} from "../../utils/baseUrl";

import axios from "axios";
import Swal from "sweetalert2";

const AddMemberLogo = () => {
  //addlabormemberlogo.php
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      skillID: "",
      title: "",
      url: "",
      discription: "-",
    },
  });
  //laborlogo id,title,photo,discription,url,created_at
  //laborskill id,userID,laborlogoID,title,photo,discription,url,created_at
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSectedFile] = useState(null);
  const [laborlogos, setLaborlogos] = useState([]);
  const [skills, setSkills] = useState([]);
  const [userId, setUserId] = useState("");
  const [laborlogoID, setLaborlogoID] = useState("");
  const [laborlogoPhoto, setLaborlogoPhoto] = useState("");
  // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
  function readLaborlogo() {
    axios.get(`${logoUrl}readlaborlogo.php`).then(function (response) {
      console.log(response.data);
      setLaborlogos(response.data);
    });
  }
  function readSkill() {
    axios.get(`${logoUrl}viewskill.php`).then(function (response) {
      console.log(response.data);
      setSkills(response.data);
    });
  }
  /*   window.localStorage.setItem("logoID", response.data.user.logoID);
  window.localStorage.setItem("logoPhoto", response.data.user.logoPhoto); */
  useEffect(() => {
    var id = localStorage.getItem("id");
    var lbid = localStorage.getItem("logoID");
    var lbPhoto = localStorage.getItem("logoPhoto");
    setUserId(id);
    setLaborlogoID(lbid);
    setLaborlogoPhoto(lbPhoto);
    // readLaborlogo();
    readSkill();
  }, []);
  /*   const [laborlogoID, setLaborlogoID] = useState("");
  const [laborlogoPhoto, setLaborlogoPhoto] = useState(""); */
  // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at
  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };

  const handleOnSubmit = (data) => {
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("laborlogoID", laborlogoID);
    formData.append("title", data.title);
    formData.append("photourl", laborlogoPhoto);
    formData.append("url", data.url);
    formData.append("discription", data.discription);
    formData.append("userID", userId);
    formData.append("memberID", id);
    formData.append("skillID", data.skillID);
    setLoading(true);
    //laborlogo id,title,photo,discription,url,created_at
    axios
      .post(`${logoUrl}addlabormemberlogo.php`, formData)
      .then(function (response) {
        console.log(response.data);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        reset();
        // notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
        setLoading(false); // Stop loading
        //locations
      });
  };
  return (
    <>
      <div className="">
        <div className="">
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            {/*             <div className="mb-2">
              <label>โลโก้</label>
              <select
                {...register("laborlogoID", {
                  required: true,
                })}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              >
                <option value=""></option>
                {laborlogos.map((item, index) => (
                  <option
                    key={index}
                    value={`${item.id}/${item.photo}/${item.url}`}
                  >
                    {item.title}
                  </option>
                ))}
              </select>
              {errors.laborlogoID && (
                <div className="my-3 text-normal text-red-500 text-xs italic">
                  ระบุโลโก้
                </div>
              )}
            </div> */}
            <div className="mb-2">
              <label>ระดับทักษะ</label>
              <select
                {...register("skillID", {
                  required: true,
                })}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              >
                <option value=""></option>
                {/* <option value="0">มองเห็นทั้งหมด</option> */}
                {skills.map((item, index) => (
                  <option key={index} value={`${item.id}`}>
                    {item.title}
                  </option>
                ))}
              </select>
              {errors.laborlogoID && (
                <div className="my-3 text-normal text-red-500 text-xs italic">
                  ระบุระดับทักษะ
                </div>
              )}
            </div>
            {/* laborlogo id,title,photo,discription,url,created_at */}

            <div className="mb-2 flex-1">
              <label>คำอธิบาย</label>
              <input
                type="text"
                name="title"
                {...register("title", {
                  required: true,
                })}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
              {errors.title && (
                <div className="my-3 text-normal text-red-500 text-xs italic">
                  ป้อนคำอธิบาย
                </div>
              )}
            </div>
            <div className="mb-2">
              <label>Url Web site</label>
              <input
                type="url"
                name="url"
                {...register("url", {
                  required: true,
                })}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
              {errors.url && (
                <div className="my-3 text-normal text-red-500 text-xs italic">
                  ป้อน Url Web site
                </div>
              )}
            </div>
            <div className="mb-2">
              <label>รายละเอียด ที่เกี่ยวข้อง(ถ้ามี)</label>
              <input
                type="text"
                name="discription"
                {...register("discription", {
                  required: true,
                })}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
              {errors.discription && (
                <div className="my-3 text-normal text-red-500 text-xs italic">
                  ป้อนรายละเอียด
                </div>
              )}
            </div>
            <div className="my-2">
              <label>รูปภาพประกอบที่เกี่ยวข้อง(ถ้ามี)</label>
              <div className="flex flex-col items-start">
                <input type="file" onChange={handleOnChange} />
              </div>
            </div>
            <div className="flex justify-end">
              <button
                disabled={loading}
                type="submit"
                className="flex w-[200px] justify-center rounded bg-primary p-3 font-medium text-gray"
              >
                {loading ? <>กรุณารอซักครู่..</> : <>บันทึก</>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddMemberLogo;
