import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  pnSalepageFileUrl,
  videoSalepageUrl,
  imgSalepageFileUrl,
  pnSalepageUrl,
  picSalepageUrl,
  gfSalepageUrl,
  gfSalepageFileUrl,
  yuSalepageUrl,
  videoSalepageFileUrl,
  disSalepageUrl,
  disSalepageFileUrl,
  laborSalepageUrl,
  laborSalepageFileUrl,
} from "../../utils/baseUrl";
// pnSalepageFileUrl
import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Swal from "sweetalert2";

// import ReactPlayer from "react-player";
import ReactPlayer from "react-player/lazy";

import { HiOutlinePhone, HiOutlineMail } from "react-icons/hi";
import { BiGlobe } from "react-icons/bi";
// import { BsFacebook } from "react-icons/bs";
import { AiOutlineFacebook, AiOutlineLink } from "react-icons/ai";
import { FaLine, FaRegIdBadge } from "react-icons/fa";

const EditSalepage = () => {
  const { id } = useParams();

  const [lineurl, setLineurl] = useState("");
  const [fburl, setFburl] = useState("");
  const [phone, setPhone] = useState("");
  const [weburl, setWeburl] = useState("");
  const [datas, setDatas] = useState({});
  const [loading, setLoading] = useState(false);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  //datas
  function readData(id) {
    axios
      .get(`${laborSalepageUrl}readsalepagebyid.php/${id}`)
      .then(function (response) {
        console.log(response.data);
        setDatas(response.data);
        setFburl(response.data?.fburl);
        setWeburl(response.data?.weburl);
        setPhone(response.data?.phone);
        setLineurl(response.data?.lineurl);
      });
  }

  useEffect(() => {
    readData(id);
    //editsalepage.php
  }, []);
  /*     lineurl: "",
    fburl: "",
    phone: "",
    weburl: "", */
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    axios
      .post(`${laborSalepageUrl}editsalepage.php`, {
        idEdit: id,
        lineurl,
        fburl,
        phone,
        weburl,
      })
      .then(function (response) {
        console.log(response.data);
        readData(id);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        // notifySuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
        setLoading(false); // Stop loading
      });
  };
  return (
    <>
      <div className="">
        <div className="">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-4">
              <div className="mb-6">
                <label>Line ID</label>
                <input
                  type="text"
                  value={lineurl}
                  onChange={(e) => setLineurl(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>

              <div className="mb-6">
                <label>Facebook</label>
                <input
                  type="url"
                  value={fburl}
                  onChange={(e) => setFburl(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
            </div>
            <div className="mb-6">
              <label>Phone</label>
              <input
                type="tel"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="mb-6">
                <label>Website</label>
                <input
                  type="url"
                  value={weburl}
                  onChange={(e) => setWeburl(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
            </div>
            <div className="flex justify-end">
              <button
                disabled={loading}
                type="submit"
                className="flex w-[200px] justify-center rounded bg-primary p-3 font-medium text-gray"
              >
                {loading ? <>กรุณารอซักครู่..</> : <>บันทึก</>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditSalepage;
