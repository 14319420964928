import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import axios from "axios";

import Swal from "sweetalert2";

import {
  settingsTypeUrl,
  partnerUrl,
  distributeUrl,
  memberUrl,
  imgLogoUrl,
  logoUrl,
} from "../utils/baseUrl";

/* import { IoTrashOutline, IoCloseSharp } from "react-icons/io5";
import {
  HiOutlineBanknotes,
  HiMiniPencilSquare,
  HiMiniPlus,
} from "react-icons/hi2"; */

// import { FiSave } from "react-icons/fi";
// import {} from "react-d"
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";

// import React from "react";
import { Breadcrumb } from "../components";
// import { Outlet, Link } from "react-router-dom";
// import { IconName } from "react-icons/ai";
import { BsPlusCircle, BsUiChecksGrid } from "react-icons/bs";
import {
  BiGridHorizontal,
  BiGridAlt,
  BiSolidGrid,
  BiSolidGridAlt,
  BiXCircle,
  FiSave,
} from "react-icons/bi";

const ShowMember = () => {
  //readskillwithmember.php
  const [loading, setLoading] = useState(false);
  const [memberData, setMemberData] = useState([]);
  const [userId, setUserId] = useState("");

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 40;
  const pagesVisited = pageNumber * usersPerPage;

  function readMember(id) {
    axios.get(`${logoUrl}readmemberlogolaber.php`).then(function (response) {
      console.log(response.data);
      setMemberData(response.data);
    });
  }
  useEffect(() => {
    /*     var user = localStorage.getItem("id");
      console.log("user: ", user); */
    /*     var user = localStorage.getItem("id");
    if (user != null) {
      setUserId(user);
    } */
    readMember();
  }, []);

  const pageCount = Math.ceil(memberData.length / usersPerPage);
  const displayAllDatas = memberData
    .slice(pagesVisited, pagesVisited + usersPerPage)
    .map((item, index) => {
      return (
        <tr key={index}>
          <td className="border-b border-[#eee] dark:border-strokedark">
            <h5 className="font-medium text-black dark:text-white">
              {item?.firstname} {item?.lastname}
            </h5>
          </td>
          <td className="border-b border-[#eee] dark:border-strokedark">
            {item.logo1 != null ? (
              <img
                src={`${imgLogoUrl}${item.logo1}`}
                className="rounded-lg m-1 w-[40px] h-[40px]"
              />
            ) : null}
            {item.logo2 !== null ? (
              <img
                src={`${imgLogoUrl}${item.logo2}`}
                className="rounded-lg m-1 w-[40px] h-[40px]"
              />
            ) : null}
            {item.logo3 !== null ? (
              <img
                src={`${imgLogoUrl}${item.logo3}`}
                className="rounded-lg m-1 w-[40px] h-[40px]"
              />
            ) : null}
            {item.logo4 !== null ? (
              <img
                src={`${imgLogoUrl}${item.logo4}`}
                className="rounded-lg m-1 w-[40px] h-[40px]"
              />
            ) : null}
          </td>
          <td className="border-b border-[#eee] dark:border-strokedark">
            {item?.email}
          </td>
          <td className="border-b border-[#eee] dark:border-strokedark">
            {item?.phone}
          </td>
          <td className="border-b border-[#eee] dark:border-strokedark">
            <div className="flex items-center space-x-3.5"></div>
          </td>
        </tr>
      );
    });
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <>
      <Breadcrumb pageName={"สมาชิกที่ได้รับโล้โก"} />
      <div className="overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        {/* ---------  */}
        <div className="p-5">
          <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
            รายการสมาชิกที่ได้รับโล้โก
          </h4>
          <div className="max-w-full overflow-x-auto">
            {/* --------  */}
            {/* <DataTable columns={columns} data={memberData} /> */}
            {/* ---------  */}
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-2 text-left dark:bg-meta-4">
                  <th className="min-w-[200px] w-full font-medium text-black dark:text-white">
                    ชื่อ-สกุล
                  </th>
                  <th className="min-w-[200px] font-medium text-black dark:text-white">
                    โลโก้
                  </th>
                  <th className="min-w-[200px] font-medium text-black dark:text-white">
                    อีเมล์
                  </th>
                  <th className="min-w-[200px] font-medium text-black dark:text-white">
                    โทรศัพท์
                  </th>
                  <th className="min-w-[100px] font-medium text-black dark:text-white">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* ---------  */}
                {displayAllDatas}

                {/* ---------  */}
              </tbody>
            </table>
            <div className="my-8">
              <ReactPaginate
                previousLabel={"ก่อนหน้า"}
                nextLabel={"ถัดไป"}
                pageCount={pageCount}
                pageRangeDisplayed={4}
                marginPagesDisplayed={2}
                onPageChange={changePage}
                containerClassName={
                  "flex items-center justify-center space-x-1"
                }
                pageClassName={
                  "px-4 py-2 rounded-full bg-gray text-gray-700 bg-gray-200 rounded-md hover:bg-graydark hover:text-white"
                }
                previousLinkClassName={
                  "flex items-center px-4 py-2 text-gray-500 bg-gray-300 rounded-md"
                }
                nextLinkClassName={
                  "px-4 py-2 font-bold text-gray-500 bg-gray-300 rounded-md hover:bg-graydark hover:text-white"
                }
                breakLabel="..."
                breakClassName="page-item"
                activeClassName=""
                /*                 pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                disabledClassName={""} */
                // activeClassName="active"
                // activeClassName=""
                renderOnZeroPageCount={null}
              />
            </div>
            {/* --------  */}
          </div>
        </div>
        {/* ---------  */}
      </div>
    </>
  );
};

export default ShowMember;
