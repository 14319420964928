import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  partnerUrl,
  distributeUrl,
  memberUrl,
  imgLogoUrl,
  logoUrl,
} from "../../utils/baseUrl";

import axios from "axios";
import Swal from "sweetalert2";

const EditSkill = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState({});
  const [selectedFile, setSectedFile] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  //skillforlabor id,title,description,created_at
  function readData(id) {
    //viewskillbyid.php
    axios.get(`${logoUrl}viewskillbyid.php/${id}`).then(function (response) {
      console.log(response.data);
      setDatas(response.data);
      setTitle(response.data?.title);
      setDescription(response.data?.description);
    });
  }

  useEffect(() => {
    readData(id);
  }, []);
  console.log(datas);
  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    //updateskillforlabor.php
    axios
      .post(`${logoUrl}updateskillforlabor.php`, {
        title,
        description,
        idEdit: id,
      })
      .then(function (response) {
        console.log(response.data);
        readData(id);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);
      });
  };
  console.log("Sk: ", datas);
  return (
    <>
      <div className="">
        <div className="">
          <form onSubmit={handleOnSubmit}>
            <div className="p-6.5">
              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  ชื่อระดับทักษะ
                </label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  รายละเอียดระดับทักษะ
                </label>
                <input
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
              <div className="flex justify-end">
                <button
                  disabled={loading}
                  type="submit"
                  className="flex w-[200px] justify-center rounded bg-primary p-3 font-medium text-gray"
                >
                  {loading ? <>กรุณารอซักครู่..</> : <>บันทึก</>}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditSkill;
