import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  partnerUrl,
  distributeUrl,
  memberUrl,
  imgLogoUrl,
  logoUrl,
} from "../../utils/baseUrl";

import axios from "axios";
import Swal from "sweetalert2";

import { IoTrashOutline, IoCloseSharp } from "react-icons/io5";
import {
  HiOutlineBanknotes,
  HiMiniPencilSquare,
  HiMiniPlus,
} from "react-icons/hi2";

import { FiSave } from "react-icons/fi";

const ViewSkill = () => {
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [userId, setUserId] = useState("");

  function readSkill(id) {
    axios.get(`${logoUrl}viewskill.php/${id}`).then(function (response) {
      console.log(response.data);
      setMyData(response.data);
    });
  }
  useEffect(() => {
    /*     var user = localStorage.getItem("id");
          console.log("user: ", user); */
    var user = localStorage.getItem("id");
    if (user != null) {
      setUserId(user);
    }
    readSkill(user);
  }, []);

  //localhost:8080/appApi/settings/accounttype/deleteactype.php/1
  const deleteData = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${logoUrl}deleteskill.php/${id}`)
          .then(function (response) {
            console.log(response.data);
            readSkill();
            // Swal.fire("Deleted!", "Your file has been deleted.", "success");
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Your work has been saved",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    });
  };
  return (
    <>
      <div className="flex flex-col gap-10">
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
            รายการระดับทักษะอาชีพ
          </h4>
          {/* -----------  */}
          <div className="max-w-full overflow-x-auto">
            {/* --------  */}
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-2 text-left dark:bg-meta-4">
                  <th className="min-w-[220px] font-medium text-black dark:text-white">
                    ระดับทักษะอาชีพ
                  </th>
                  <th className="min-w-[220px] w-full font-medium text-black dark:text-white">
                    รายละเอียดทักษะอาชีพ
                  </th>
                  <th className="min-w-[100px] font-medium text-black dark:text-white">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {myData.map((item, index) => (
                  <tr key={index}>
                    <td className="border-b border-[#eee] dark:border-strokedark">
                      {item?.title}
                    </td>
                    <td className="border-b border-[#eee] dark:border-strokedark">
                      {item?.description}
                    </td>
                    <td className="border-b border-[#eee] dark:border-strokedark">
                      <div className="flex items-center space-x-3.5">
                        <button
                          onClick={() => deleteData(item?.id)}
                          className="hover:text-primary"
                        >
                          <IoTrashOutline size={22} />
                        </button>
                        <Link to={`editskill/${item?.id}`} className="">
                          <HiMiniPencilSquare size={22} />
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* --------  */}
          </div>
          {/* -----------  */}
        </div>
      </div>
    </>
  );
};

export default ViewSkill;
