import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  partnerUrl,
  distributeUrl,
  memberUrl,
  logoUrl,
} from "../../utils/baseUrl";

import axios from "axios";
import Swal from "sweetalert2";

const EditMember = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState({});
  const [firstnameEdit, setFirstnameEdit] = useState("");
  const [lastnameEdit, setLastnameEdit] = useState("");
  const [phoneEdit, setPhoneEdit] = useState("");
  const [emailEdit, setEmailEdit] = useState("");
  const [passwordEdit, setPasswordEdit] = useState("");
  const [pltype, setPltype] = useState("");
  const [partnerData, setPartnerData] = useState({});
  const [dataType, setDataType] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const handleCbOnChange = () => {
    setIsChecked(!isChecked);
  };
  function readData(id) {
    axios.get(`${memberUrl}readbyid.php/${id}`).then(function (response) {
      console.log(response.data);
      setDatas(response.data);
      setFirstnameEdit(response.data?.firstname);
      setLastnameEdit(response.data?.lastname);
      setPhoneEdit(response.data?.phone);
      setEmailEdit(response.data?.email);
      setPasswordEdit(response.data?.password);
      // setPltype(response.data?.pltype);
      const cb = response.data?.isLabor == 1 ? true : false;
      // setEmergency();
      setIsChecked(cb);
    });
  }

  useEffect(() => {
    readData(id);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    axios
      .post(`${logoUrl}updatememberlabor.php`, {
        idEdit: id,
        firstnameEdit,
        lastnameEdit,
        emailEdit,
        phoneEdit,
        passwordEdit,
        isLogo: isChecked,
      })
      .then(function (response) {
        console.log(response.data);
        readData(id);
        setIsChecked(false);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        // notifySuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
        setLoading(false); // Stop loading
      });
  };

  return (
    <>
      <div className="">
        <div className="">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-4">
              <div className="mb-6">
                <label>First Name:</label>
                <input
                  type="text"
                  value={firstnameEdit}
                  onChange={(e) => setFirstnameEdit(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>

              <div className="mb-6">
                <label>Last Name:</label>
                <input
                  type="text"
                  value={lastnameEdit}
                  onChange={(e) => setLastnameEdit(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
            </div>
            <div className="mb-6">
              <label>Phone:</label>
              <input
                type="tel"
                value={phoneEdit}
                onChange={(e) => setPhoneEdit(e.target.value)}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="mb-6">
                <label>E-mail:</label>
                <input
                  type="email"
                  value={emailEdit}
                  onChange={(e) => setEmailEdit(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
              <div className="mb-6">
                <label>Password:</label>
                <input
                  type="password"
                  value={passwordEdit}
                  onChange={(e) => setPasswordEdit(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
            </div>
            <div className="mb-2">
              <div className="flex gap-2">
                <input
                  type="checkbox"
                  id="some_id"
                  checked={isChecked}
                  onChange={handleCbOnChange}
                  className="
    appearance-none w-4 h-4 border-2 border-primary rounded-sm bg-white
    mt-1 shrink-0
    checked:bg-success checked:border-0"
                />
                <label htmlFor="some_id">
                  สมาชิกกรมพัฒนาฝีมือแรงงาน(
                  <strong>{isChecked ? "ใช่" : "ไม่ใช่"}</strong>)
                </label>
              </div>
              {/*             <label>Emergency</label>
            <input
              type="checkbox"
              id="topping"
              name="topping"
              value="Paneer"
              checked={isChecked}
              onChange={handleOnChange}
            /> */}
            </div>
            <div className="flex justify-end">
              <button
                disabled={loading}
                type="submit"
                className="flex w-[200px] justify-center rounded bg-primary p-3 font-medium text-gray"
              >
                {loading ? <>กรุณารอซักครู่..</> : <>บันทึก</>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditMember;
