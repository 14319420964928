import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  settingsTypeUrl,
  appinfoUrl,
  mainOccUrl,
  locationUrl,
  imgmainOccUrl,
  picSalepageUrl,
  videoSalepageUrl,
  imgSalepageFileUrl,
  videoSalepageFileUrl,
  pnSalepageUrl,
  pnSalepageFileUrl,
  disSalepageUrl,
  disSalepageFileUrl,
  laborSalepageUrl,
  laborSalepageFileUrl,
} from "../../utils/baseUrl";

import axios from "axios";

import toast, { Toaster } from "react-hot-toast";

import Swal from "sweetalert2";

import { IoTrashOutline, IoCloseSharp } from "react-icons/io5";
import {
  HiOutlineBanknotes,
  HiMiniPencilSquare,
  HiMiniPlus,
} from "react-icons/hi2";
import { TbPhotoEdit } from "react-icons/tb";

import { FaRegEye } from "react-icons/fa";

import { FiSave } from "react-icons/fi";

import {
  BiGridHorizontal,
  BiGridAlt,
  BiSolidGrid,
  BiSolidGridAlt,
  BiXCircle,
  BiCopyAlt,
} from "react-icons/bi";

const ViewSalepage = () => {
  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState([]);
  const [userId, setUserId] = useState("");
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  /*   laborSalepageUrl,
  laborSalepageFileUrl, */
  function readData(id) {
    axios
      .get(`${laborSalepageUrl}getsalepage.php/${id}`)
      .then(function (response) {
        console.log("Sale Page: ", response.data);
        setDatas(response.data);
      });
  }

  useEffect(() => {
    var user = localStorage.getItem("id");
    console.log("user: ", user);
    setUserId(user);
    readData(user);
  }, []); //userId, datas

  /*   useCallback(() => {
      readData(userId);
      return () => {};
    }, []); */

  const deleteData = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${laborSalepageUrl}delete.php/${id}`)
          .then(function (response) {
            console.log(response.data);
            readData(userId);
            // Swal.fire("Deleted!", "Your file has been deleted.", "success");
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Your work has been saved",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    });
  };

  console.log("My salepage ALL: ", datas);
  /*   console.log("My Data: ", datas[0][0]?.labor);
  console.log("My IMG: ", datas[0][0]?.imgs); */
  return (
    <>
      <div className="">
        <div className="">
          <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
            รายการส่งเสริมการขาย(Web Salepage)
            <br />
            คลิกที่รูปเพื่อเลือกรูปใหม่
          </h4>
          {/* ------------- */}
          <div className="">
            {datas.map((item, idx) => {
              return (
                <div
                  key={`labor_${idx}`}
                  className="rounded-lg border border-[#EDEDED] shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1"
                >
                  {/* <div>{JSON.stringify(item?.imgs)}</div> windowSize*/}
                  <div className="grid grid-cols-1 md:grid-cols-4 gap-2">
                    {item?.imgs.map((pic, index) => (
                      <div
                        key={`img_${index}`}
                        className="rounded-lg overflow-hidden"
                      >
                        <Link to={`editimg/${pic?.id}`}>
                          <img
                            src={`${laborSalepageFileUrl}${pic.photo}`}
                            // className="rounded-lg m-2 w-full md:w-[300px]"
                            className={`w-full h-[${
                              windowSize[0] * 0.5
                            }px] object-cover`}
                          />
                        </Link>
                      </div>
                    ))}
                  </div>
                  <div className="rounded-lg border border-[#EDEDED] p-2 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1">
                    <div className="mt-2 flex justify-center items-center md:justify-end">
                      {/*                       <h2>
                        {idx}#{item?.id}
                      </h2>
                      <h3>{item?.url}</h3> */}
                      <CopyToClipboard
                        text={item?.url}
                        onCopy={() => toast.success("Successfully created!")}
                      >
                        <button className="bg-gray-500 mr-4 hover:bg-gray-700 text-center py-1 px-4 rounded">
                          <BiCopyAlt size={22} />
                        </button>
                      </CopyToClipboard>

                      <Link
                        to={`previewonesalepage/${item?.id}`}
                        className="mr-1 bg-blue-500 hover:bg-blue-700 text-center py-1 px-4 rounded"
                      >
                        <FaRegEye size={22} />
                      </Link>
                      <Link
                        to={`editsalepage/${item?.id}`}
                        className="mr-1 bg-blue-500 hover:bg-blue-700 text-center py-1 px-4 rounded"
                      >
                        <HiMiniPencilSquare size={22} />
                      </Link>
                      {/*                       <Link
                        to={`editimg/${item?.id}`}
                        className="mr-1 bg-blue-500 hover:bg-blue-700 text-center py-1 px-4 rounded"
                      >
                        <TbPhotoEdit size={22} />
                      </Link> */}
                      <button
                        // onClick={() => confirmDelete(item.id)}
                        onClick={() => deleteData(item?.id)}
                        className="bg-red-500 mr-4 hover:bg-red-700 text-center py-1 px-4 rounded"
                      >
                        <IoTrashOutline size={22} />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
            {/*             {datas.map((item, idx) => {
              return (
                <div key={idx}>
                  <div>{JSON.stringify(item[idx].labor)}</div>
                  <div>{JSON.stringify(item[idx].imgs)}</div>
                </div>
              );
            })} */}
            {/*             {datas.map((item, idx) => {
              return <div>{JSON.stringify(item.labor)}</div>;
            })} */}
            {/* {JSON.stringify(datas)} */}
            {/* <h2>{userId}</h2> */}
            {/* locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at */}
            {/*             {datas.map((item, index) => (
              <div key={index} className="">
                <div className="flex flex-col gap-2 md:flex-row relative w-full">
                  <div className="flex w-full flex-col w-full md:flex-row">
                    {JSON.stringify(item)} */}
            {/*                     {item.picture != "" ? (
                      <img
                        src={`${laborSalepageFileUrl}${item.picture}`}
                        className="rounded-lg m-2 w-full md:w-[300px]"
                      />
                    ) : (
                      ""
                    )}
                    {item.picture2 != "" ? (
                      <img
                        src={`${laborSalepageFileUrl}${item.picture2}`}
                        className="rounded-lg m-2 w-full md:w-[300px]"
                      />
                    ) : (
                      ""
                    )}
                    {item.picture3 != "" ? (
                      <img
                        src={`${laborSalepageFileUrl}${item.picture3}`}
                        className="rounded-lg m-2 w-full md:w-[300px]"
                      />
                    ) : (
                      ""
                    )} */}
            {/* </div> */}
            {/*                   <div className="absolute right-0 top-0 bg-white p-2 border rounded-xl">
                    <div className="mt-2 flex justify-end items-end">
                      <CopyToClipboard
                        text={item?.salepageurl}
                        onCopy={() => toast.success("Successfully created!")}
                      >
                        <button className="bg-gray-500 mr-4 hover:bg-gray-700 text-center py-1 px-4 rounded">
                          <BiCopyAlt size={22} />
                        </button>
                      </CopyToClipboard>

                      <button
                        // onClick={() => confirmDelete(item.id)}
                        onClick={() => deleteData(item.id)}
                        className="bg-red-500 mr-4 hover:bg-red-700 text-center py-1 px-4 rounded"
                      >
                        <IoTrashOutline size={22} />
                      </button>
                      <Link
                        to={`previewsalepage/${item.id}`}
                        className="mr-1 bg-blue-500 hover:bg-blue-700 text-center py-1 px-4 rounded"
                      >
                        <FaRegEye size={22} />
                      </Link>
                    </div>
                  </div> */}
            {/*                 </div>
                <Toaster />
              </div>
            ))} */}
            <Toaster />
          </div>
          {/* ------------- */}
        </div>
      </div>
    </>
  );
};

export default ViewSalepage;
