import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { logoUrl } from "../utils/baseUrl";
import axios from "axios";
import conditionText from "../utils/conditionText";
import { useNavigate } from "react-router-dom";

const Policy = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [isPolicy, setIspolicy] = useState("");

  const validationSchema = Yup.object().shape({
    acceptTerms: Yup.bool().oneOf([true], "ต้องยอมรับเงื่อนไขการใช้งาน"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;
  useEffect(() => {
    var user = localStorage.getItem("id");
    var ispolicy = localStorage.getItem("ispolicy");
    console.log("user: ", user);
    console.log("ispolicy: ", ispolicy);
    setUserId(user);
    setIspolicy(ispolicy);
  }, []);

  function onSubmit(data) {
    // display form data on success
    // alert("SUCCESS!! :-)\n\n" + JSON.stringify(data, null, 4));
    handleOnSubmit(data);
    return false;
  }
  //disaccount,partneraccount
  const handleOnSubmit = (data) => {
    axios
      .post(`${logoUrl}accept.php`, {
        pid: userId,
        ispolicy: data.acceptTerms,
      })
      .then(function (response) {
        console.log(response.data);
        reset();
        // notifySuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
        navigate("/");
      });
  };
  return (
    <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
      <div className="container p-6 mx-auto bg-white rounded-md shadow-xl">
        <div className="block p-6 rounded-lg shadow-lg bg-white">
          {/*  lg:max-w-xl max-w-md<h3 className="my-4 text-sm">ลิงค์เข้าใช้งานระบบ</h3> onSubmit={{}} handleSubmit(handleOnSubmit) max-w-md*/}
          <h1 className="text-3xl font-semibold text-center">
            ข้อตกลงและเงื่อนไขการใช้บริการ {isPolicy}
          </h1>
          <div className="mb-4">
            {/* <p className="text-sm">{conditionText}</p> */}
          </div>
          <p
            className="mb-4"
            dangerouslySetInnerHTML={{ __html: conditionText }}
          ></p>
          <div className="mb-4">
            <p className="text-sm">
              ในการดำเนินการสมัครเข้าร่วมรับค่าโฆษณากับกิฟท์ไลค์พลัส
              ข้าพเจ้าตกลงและยินยอมให้กิฟท์ไลค์พลัส เก็บรวบรวม ใช้และ
              เปิดเผยข้อมูลที่ข้าพเจ้าให้ไว้ในนามลิงค์ของพาร์ทเนอร์ ในการรับค่า
              โฆษณา การตลาด เพื่อดำเนินการตามที่จำเป็นภายใต้นโยบายส่วน
              บุคคลของกิฟท์ไลค์พลัส ที่ข้าพเจ้าได้อ่านและเข้าใจเนื้อหาทั้งหมด
              แล้ว
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="block">
              <div className="mt-2">
                <label
                  htmlFor="acceptTerms"
                  className="inline-flex items-center text-sm"
                >
                  <input
                    name="acceptTerms"
                    type="checkbox"
                    {...register("acceptTerms")}
                    id="acceptTerms"
                    className="mr-2 w-6 h-6 text-green-600 border-0 rounded-md focus:ring-0"
                  />
                  ยอมรับเงื่อนไขการใช้งาน {isPolicy}
                </label>
                <div className="my-3 text-xl text-danger italic">
                  {errors.acceptTerms?.message}
                </div>
              </div>
            </div>
            <div>
              <button
                type="submit"
                disabled={isPolicy != 0 ? true : false}
                className="w-full py-3 px-3 text-center  text-sm bg-primary border rounded-lg text-white"
              >
                ยอมรับเงื่อนไขการใช้งาน
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Policy;
