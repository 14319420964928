import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import axios from "axios";

import Swal from "sweetalert2";

import {
  settingsTypeUrl,
  partnerUrl,
  distributeUrl,
  memberUrl,
  logoUrl,
  imgLogoUrl,
} from "../../utils/baseUrl";

import { IoTrashOutline, IoCloseSharp } from "react-icons/io5";
import {
  HiOutlineBanknotes,
  HiMiniPencilSquare,
  HiMiniPlus,
} from "react-icons/hi2";

import { FiSave } from "react-icons/fi";

const SearchAllmember = () => {
  const [loading, setLoading] = useState(false);
  const [memberData, setMemberData] = useState([]);
  const [userId, setUserId] = useState("");
  const [txtSearch, setTxtSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [searchItem, setSearchItem] = useState("");
  const [companyInfoData, setCompanyInfoData] = useState([]);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    searchCompany(searchTerm);
    setSearchItem(searchTerm);
  };

  function searchCompany(txtOcc) {
    setIsLoading(true);
    /*     const result = detail.replace(/[^0-9]/g, '');
    let result2 = result.slice(-5);
    console.log(result2); */
    axios
      // .get(`${shopUrl}searchoccupation.php/${txtOcc}`)
      .post(`${logoUrl}searchmember.php`, {
        searchText: txtOcc,
      })
      .then(function (response) {
        console.log("Search---------");
        const data = response.data;
        console.log("Type: ", typeof data);
        console.log(response?.data);
        console.log("---------Search");
        setMemberData(response?.data);
        // memberID
        // readLogo(response?.data.memberID);
        setIsLoading(false);
      });
  }

  /*   function searchCompany() {
    setIsLoading(true);

    axios
      .post(`${logoUrl}searchmember.php`, {
        searchText: txtSearch,
      })
      .then(function (response) {
        console.log("Search---------");
        const data = response.data;
        console.log("Type: ", typeof data);
        console.log(response?.data);
        console.log("---------Search");
        setMemberData(response?.data);
        setIsLoading(false);
      });
  } */

  /*   useEffect(() => {
    var user = localStorage.getItem("id");
    if (user != null) {
      setUserId(user);
    }
  }, [txtSearch]); */

  useEffect(() => {
    searchCompany(searchItem);
  }, []);
  return (
    <>
      <div className="flex flex-col gap-10">
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="relative z-20 p-4">
            <div className="flex items-center justify-center gap-1 md:p-4 rounded-md w-full">
              <div className="flex gap-4 w-full">
                {/* <span>ระบุวันที่:{checkinData} </span> */}

                {/*                 <input
                  type="text"
                  value={txtSearch}
                  onChange={(e) => setTxtSearch(e.target.value)}
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                /> */}
                <input
                  type="text"
                  className="w-full mb-2 border py-2 px-3 text-grey-darkest"
                  value={searchItem}
                  onChange={handleInputChange}
                  placeholder={`ค้นหาสมาชิก`}
                />

                {/*                 <button
                  className="flex w-[150px] py-3 px-5 justify-center rounded bg-primary p-1 font-medium text-gray"
                  type="button"
                  //   onClick={(e) => searchCompany(e)}
                  onClick={searchCompany}
                >
                  ค้นหา
                </button> */}
              </div>
            </div>
          </div>
          {/* ----------  */}
          <div className="max-w-full overflow-x-auto">
            {/* --------  */}
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-2 text-left dark:bg-meta-4">
                  <th className="min-w-[200px] w-full font-medium text-black dark:text-white">
                    ชื่อ-สกุล
                  </th>
                  {/*                   <th className="min-w-[200px] font-medium text-black dark:text-white">
                    โลโก้
                  </th> */}
                  <th className="min-w-[200px] font-medium text-black dark:text-white">
                    อีเมล์
                  </th>
                  <th className="min-w-[200px] font-medium text-black dark:text-white">
                    โทรศัพท์
                  </th>
                  <th className="min-w-[100px] font-medium text-black dark:text-white">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {memberData.map((item, index) => (
                  <tr key={index}>
                    <td className="border-b border-[#eee] dark:border-strokedark">
                      <h5 className="font-medium text-black dark:text-white">
                        {item?.firstname} {item?.lastname}
                      </h5>
                      {/*   {item?.id}#                      <p className="text-sm"> {item?.address}</p>
                  <p className="text-sm"> {item?.phone}</p> */}
                    </td>
                    {/*                     <td className="border-b border-[#eee] dark:border-strokedark">
                      {item.logo1 != null ? (
                        <img
                          src={`${imgLogoUrl}${item.logo1}`}
                          className="rounded-lg m-1 w-[40px] h-[40px]"
                        />
                      ) : null}
                      {item.logo2 !== null ? (
                        <img
                          src={`${imgLogoUrl}${item.logo2}`}
                          className="rounded-lg m-1 w-[40px] h-[40px]"
                        />
                      ) : null}
                      {item.logo3 !== null ? (
                        <img
                          src={`${imgLogoUrl}${item.logo3}`}
                          className="rounded-lg m-1 w-[40px] h-[40px]"
                        />
                      ) : null}
                      {item.logo4 !== null ? (
                        <img
                          src={`${imgLogoUrl}${item.logo4}`}
                          className="rounded-lg m-1 w-[40px] h-[40px]"
                        />
                      ) : null}
                    </td> */}
                    <td className="border-b border-[#eee] dark:border-strokedark">
                      {item?.email}
                    </td>
                    <td className="border-b border-[#eee] dark:border-strokedark">
                      {item?.phone}
                    </td>
                    <td className="border-b border-[#eee] dark:border-strokedark">
                      <div className="flex items-center space-x-3.5">
                        {/*                         <button
                          onClick={() => deleteData(item?.id)}
                          className="hover:text-primary"
                        >
                          <IoTrashOutline size={22} />
                        </button> 
                         className="flex w-[200px] justify-center rounded bg-primary p-3 font-medium text-gray"
                        */}
                        <Link
                          className="flex w-[110px] justify-center rounded bg-primary py-2 font-medium text-gray"
                          to={`viewmemberwithcompany/${item?.id}`}
                        >
                          กำหนดโลโก้
                        </Link>
                        {/*                         <Link
                          className="flex w-[110px] justify-center rounded bg-primary py-2 font-medium text-gray"
                          to={`editlogomember/${item?.id}`}
                        >
                          แก้ไข
                        </Link> */}
                        {/*                         <Link to={`addsalary/${item?.id}`} className="">
                      บันทึกเงินเดือน
                    </Link> */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* --------  */}
          </div>
          {/* ----------  */}
        </div>
      </div>
    </>
  );
};

export default SearchAllmember;
