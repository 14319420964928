import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  appinfoUrl,
  mainOccUrl,
  locationUrl,
  imgmainOccUrl,
  picSalepageUrl,
  videoSalepageUrl,
  imgSalepageFileUrl,
  videoSalepageFileUrl,
  pnSalepageUrl,
  disSalepageUrl,
  disSalepageFileUrl,
  laborSalepageUrl,
  laborSalepageFileUrl,
} from "../../utils/baseUrl";

import axios from "axios";

import Swal from "sweetalert2";

const STATUS_IDLE = 0;
const STATUS_UPLOADING = 1;
const CreateSalepage = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: "0",
      disId: " 0",
      salepageId: "0",
      lineurl: "",
      fburl: "",
      phone: "",
      weburl: "",
    },
  });

  const [loading, setLoading] = useState(false);
  //   const [selectedFile, setSectedFile] = useState(null);
  const [userId, setUserId] = useState("");
  const [files, setFiles] = useState([]);
  const [status, setStatus] = useState(STATUS_IDLE);
  //   const [filedatas, setfiledatas] = useState([]);
  //   let filedatas = [];
  useEffect(() => {
    var user = localStorage.getItem("id");
    // console.log("user: ", user);
    setUserId(user);
  }, []);

  /*   const packFiles = (files) => {
    const data = new FormData();

    [...files].forEach((file, i) => {
      data.append(`file-${i}`, file, file.name);
    });
    return data;
  }; */

  /*   const handleUploadClick = () => {
    if (files.length) {
      const data = packFiles(files);
        uploadFiles(data);
    }
  }; */

  /*   const uploadFiles = (data)=> {
    setStatus(STATUS_UPLOADING);

    fetch(API_URL, {
        method: API_METHOD,
        body: data,
    })
    .then((res) => res.json())
    .then((data) => console.log(data))
    .catch((err) => console.error(err))
    .finally(() => setStatus(STATUS_IDLE))
} */
  //savesalepage.php
  const handleOnSubmit = (data) => {
    setLoading(true);

    // return;
    let arrayImages = [];
    const formData = new FormData();
    /*     [...files].forEach((file, i) => {
      formData.append(`file-${i}`, file, file.name);
    }); */
    for (let i = 0; i < files.length; i++) {
      console.log("File ", files[i]);
      formData.append("images[]", files[i]);
      //   arrayImages.push(files[i]);
    }
    // console.log("Multifiles: ", arrayImages);
    formData.append("lineurl", data.lineurl);
    formData.append("phone", data.phone);
    // formData.append("fileData", files);
    // formData.append("images", arrayImages);
    formData.append("userId", userId);
    formData.append("fburl", data.fburl);
    formData.append("weburl", data.weburl);
    formData.append("salepageId", "0");
    axios
      .post(`${laborSalepageUrl}savesalepagemuli.php`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        // console.log(response.data);
        reset();
        setFiles([]);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false); // Stop loading
      });
  };

  const renderFileList = () => (
    <ol className="rounded-lg border border-[#EDEDED] p-2 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1">
      {[...files].map((f, i) => (
        <li key={i}>
          {f.name} - {f.type}
        </li>
      ))}
    </ol>
  );

  /*   const renderButtonStatus = () =>
    status === STATUS_IDLE ? "Send to server" : <img src="./load.svg" />; */
  //
  return (
    <>
      <div className="">
        <div className="">
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="mb-4">
              <label>Line ID</label>
              <input
                type="text"
                name="lineurl"
                {...register("lineurl", {
                  required: true,
                })}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
              {errors.lineurl && (
                <p className="text-danger text-xs italic mt-1">Enter Line ID</p>
              )}
            </div>
            <div className="mb-4">
              <label>Facebook</label>
              <input
                type="text"
                name="fburl"
                {...register("fburl", {
                  required: true,
                })}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
              {errors.fburl && (
                <p className="text-danger text-xs italic mt-1">
                  Enter Facebook
                </p>
              )}
            </div>
            <div className="mb-4">
              <label>Website</label>
              <input
                type="text"
                name="weburl"
                {...register("weburl", {
                  required: true,
                })}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
              {errors.weburl && (
                <p className="text-danger text-xs italic mt-1">Enter Website</p>
              )}
            </div>

            <div className="mb-4">
              <label>Phone Number</label>
              <input
                type="tel"
                name="phone"
                {...register("phone", {
                  required: true,
                })}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
              {errors.phone && (
                <p className="text-danger text-xs italic mt-1">
                  Enter Phone Number
                </p>
              )}
            </div>
            <div className="mb-4">
              <label className="mr-2">เลือกหลายรูปกด Ctrl ค้างไว้</label>
              <input
                type="file"
                accept="image/*"
                multiple
                onChange={(e) => setFiles(e.target.files)}
              />
            </div>

            {/*         <div className="mb-4">
          <label>Image 1</label>
          <input type="file" onChange={handleOnChange} />
        </div>
        <div className="mb-4">
          <label>Image 2</label>
          <input type="file" onChange={handleTwOnChange} />
        </div>
        <div className="mb-4">
          <label>Image 3</label>
          <input type="file" onChange={handleTrOnChange} />
        </div> */}
            <div className="flex justify-end">
              <button
                disabled={loading}
                type="submit"
                className="flex w-[200px] justify-center rounded bg-primary p-3 font-medium text-gray"
              >
                {loading ? <>กรุณารอซักครู่..</> : <>บันทึก</>}
              </button>
            </div>
          </form>
        </div>
        <div className="mt-4">{renderFileList()}</div>
      </div>
    </>
  );
};

export default CreateSalepage;
