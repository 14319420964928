import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  settingsTypeUrl,
  partnerUrl,
  distributeUrl,
  memberUrl,
  imgLogoUrl,
  logoUrl,
} from "../../utils/baseUrl";

import axios from "axios";
import Swal from "sweetalert2";

const AddSkill = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      description: "",
    },
  });
  //   skillforlabor id,title,description,created_at
  const [loading, setLoading] = useState(false);
  const handleOnSubmit = (data) => {
    setLoading(true);

    axios
      .post(`${logoUrl}addskill.php`, {
        title: data.title,
        description: data.description,
      })
      .then(function (response) {
        console.log(response.data);
        reset();
        // toast.success("Successfully created!");
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        // notifySuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
        setLoading(false); // Stop loading
      });
  };
  return (
    <>
      <div className="">
        <div className="">
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="mb-2">
              <label>ชื่อระดับทักษะ</label>
              <input
                type="text"
                // placeholder="ชื่อจริง"
                name="title"
                {...register("title", {
                  required: true,
                })}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
              {errors.title && (
                <div className="my-3 text-sm italic text-red-500">
                  ป้อนชื่อระดับทักษะ
                </div>
              )}
            </div>
            {/* //   skillforlabor id,title,description,created_at */}
            <div className="mb-2">
              <label>รายละเอียดระดับทักษะ</label>
              <input
                type="text"
                // placeholder="นามสกุลจริง"
                name="description"
                {...register("description", {
                  required: true,
                })}
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
              {errors.description && (
                <div className="my-3 text-sm italic text-red-500">
                  ป้อนรายละเอียด
                </div>
              )}
            </div>

            <div className="flex justify-end">
              <button
                disabled={loading}
                type="submit"
                className="flex w-[200px] justify-center rounded bg-primary p-3 font-medium text-gray"
              >
                {loading ? <>กรุณารอซักครู่..</> : <>บันทึก</>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddSkill;
