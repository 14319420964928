import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  pnSalepageFileUrl,
  videoSalepageUrl,
  imgSalepageFileUrl,
  pnSalepageUrl,
  picSalepageUrl,
  gfSalepageUrl,
  gfSalepageFileUrl,
  yuSalepageUrl,
  videoSalepageFileUrl,
  disSalepageUrl,
  disSalepageFileUrl,
  laborSalepageUrl,
  laborSalepageFileUrl,
} from "../../utils/baseUrl";
// pnSalepageFileUrl
import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Swal from "sweetalert2";

// import ReactPlayer from "react-player";
import ReactPlayer from "react-player/lazy";

import { HiOutlinePhone, HiOutlineMail } from "react-icons/hi";
import { BiGlobe } from "react-icons/bi";
// import { BsFacebook } from "react-icons/bs";
import { AiOutlineFacebook, AiOutlineLink } from "react-icons/ai";
import { FaLine, FaRegIdBadge } from "react-icons/fa";
import { TbPhotoEdit } from "react-icons/tb";
const EditImg = () => {
  const { id } = useParams();
  const [datas, setDatas] = useState({});
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState(null);

  const [selectedIndex, setSectedIndex] = useState(null);
  const [selectedFile, setSectedFile] = useState(null);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    if (e.target.files[0]) {
      setSectedFile(e.target.files[0]);
      setFileName(URL.createObjectURL(e.target.files[0])); //.name
      // console.log("IMG: ", e.target.files[0]);
    }
  };
  function readData(id) {
    axios
      .get(`${laborSalepageUrl}readsalepageimgbyid.php/${id}`)
      .then(function (response) {
        // console.log(response.data);
        setDatas(response.data);
      });
  }

  useEffect(() => {
    readData(id);
    //editsalepage.php
  }, []);
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("fileData", selectedFile);
    formData.append("imgId", id);

    axios
      .post(`${laborSalepageUrl}saveeditimg.php`, formData)
      .then(function (response) {
        // console.log(response.data);

        setSectedFile(null);
        readData(id);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false); // Stop loading
      });
  };

  console.log("My salepage IMG: ", datas);
  return (
    <>
      <div className="">
        <div className="">
          <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
            คลิกที่รูปเพื่อเลือกรูปใหม่#{id}
          </h4>
          {/* ------------- */}
          <div className="">
            <div className="my-4">
              {fileName !== null ? (
                <>
                  <img
                    src={fileName}
                    className={`w-full h-[${
                      windowSize[0] * 0.5
                    }px] object-cover`}
                  />
                </>
              ) : null}
            </div>
            <div className="rounded-lg overflow-hidden relative">
              <label
                htmlFor="file"
                className="flex gap-4 items-center text-white"
              >
                <img
                  src={`${laborSalepageFileUrl}${datas.photo}`}
                  className={`w-full h-[${windowSize[0] * 0.5}px] object-cover`}
                />
              </label>
              <input
                type="file"
                id="file"
                onChange={handleOnChange}
                className="hidden"
              />
              <div className="flex justify-end mt-2">
                {fileName !== null ? (
                  <>
                    <button
                      onClick={handleSubmit}
                      className="bg-success text-white hover:bg-danger text-center py-1 px-4 rounded"
                    >
                      <TbPhotoEdit size={22} color="#FFF" />#{datas?.id}
                    </button>
                  </>
                ) : (
                  <>
                    <strong>กรุณาเลือกรูปที่ต้องการแก้ไข</strong>
                  </>
                )}
              </div>
            </div>
            );
            {/*             <div className="grid grid-cols-1 md:grid-cols-4 gap-2">
              {datas.map((item, idx) => {
                return (
                  <div
                    key={`img_${idx}`}
                    className="rounded-lg overflow-hidden relative"
                  >
                    <label
                      htmlFor="file"
                      className="flex gap-4 items-center text-white"
                    >
                      <img
                        src={`${laborSalepageFileUrl}${item.photo}`}
                        className={`w-full h-[${
                          windowSize[0] * 0.5
                        }px] object-cover`}
                      />
                    </label>
                    <input
                      type="file"
                      id="file"
                      onChange={handleOnChange}
                      className="hidden"
                    />
                    <input
                      type="hidden"
                      value={selectedFile}
                      onChange={(e) => setSectedIndex(e.target.value)}
                    />
                    <div className="flex justify-end mt-2">
                      <button
                        // onClick={() => confirmDelete(item.id)}
                        onClick={() => {}}
                        className="bg-success text-white hover:bg-danger text-center py-1 px-4 rounded"
                      >
                        <TbPhotoEdit size={22} color="#FFF" />#{item?.id}
                      </button>
                    </div>
                  </div>
                );
              })}
            </div> */}
            <Toaster />
          </div>
          {/* ------------- */}
        </div>
      </div>
    </>
  );
};

export default EditImg;
