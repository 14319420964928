import React from "react";
import { Breadcrumb } from "../components";
import { Outlet, Link } from "react-router-dom";
// import { IconName } from "react-icons/ai";
import { BsPlusCircle, BsUiChecksGrid } from "react-icons/bs";
import {
  BiGridHorizontal,
  BiGridAlt,
  BiSolidGrid,
  BiSolidGridAlt,
  BiXCircle,
  FiSave,
} from "react-icons/bi";

const Member = () => {
  return (
    <>
      <Breadcrumb pageName={"สมาชิก"} />
      <div className="overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="relative z-20 h-35 p-4">
          <div className="flex items-center justify-end gap-1 md:p-4 rounded-md overflow-hidden">
            <Link to={""} className="">
              <BiSolidGridAlt size={22} />
            </Link>
            <Link to={"addmember"} className="">
              <BsPlusCircle size={22} />
            </Link>
          </div>
        </div>
        {/* ---------  */}
        <div className="p-5">
          <Outlet />
        </div>
        {/* ---------  */}
      </div>
    </>
  );
};

export default Member;
