import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  pnSalepageFileUrl,
  videoSalepageUrl,
  imgSalepageFileUrl,
  pnSalepageUrl,
  picSalepageUrl,
  gfSalepageUrl,
  gfSalepageFileUrl,
  yuSalepageUrl,
  videoSalepageFileUrl,
  disSalepageUrl,
  disSalepageFileUrl,
  laborSalepageUrl,
  laborSalepageFileUrl,
} from "../../utils/baseUrl";
// pnSalepageFileUrl
import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

import Swal from "sweetalert2";

// import ReactPlayer from "react-player";
import ReactPlayer from "react-player/lazy";

import { HiOutlinePhone, HiOutlineMail } from "react-icons/hi";
import { BiGlobe } from "react-icons/bi";
// import { BsFacebook } from "react-icons/bs";
import { AiOutlineFacebook, AiOutlineLink } from "react-icons/ai";
import { FaLine, FaRegIdBadge } from "react-icons/fa";

const PreviewOneSalepage = () => {
  const { id } = useParams();
  const [datas, setDatas] = useState({});
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const saleurl = "/salepage/";
  const regisurl = "/register/";
  function readDataWithID(id) {
    //getsalepagebyid.php
    axios
      .get(`${laborSalepageUrl}getsalepagebyid.php/${id}`)
      .then(function (response) {
        console.log(response.data);
        setDatas(response.data);
      });
  }

  useEffect(() => {
    readDataWithID(id);
    // readSalepages();
  }, []);

  console.log("SP: ", datas);

  return (
    <>
      <div className="flex flex-col gap-10 md:w-1/2 mx-auto">
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <h4 className="mb-6 text-xl  text-center font-semibold text-black dark:text-white">
            Web Salepage
          </h4>
          {/* ---------  */}
          {/* {JSON.stringify(datas.imgs)} */}

          {datas?.imgs?.slice(0, 1).map((pic, idx) => (
            <div key={`img_${idx}`}>
              <img
                src={`${laborSalepageFileUrl}${pic.photo}`}
                className={`w-full h-[${windowSize[0] * 0.5}px] object-cover`}
              />
            </div>
          ))}
          {/* <h2>{datas.imgs.length}</h2> */}
          {/*           <div className="flex gap-2 flex-col md:flex-row">
            {datas.fb}/{datas.lineurl}/{datas.phone}
          </div> */}
          <div className="flex gap-2 flex-col md:flex-row  border border-[#EDEDED] shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden">
            {datas?.fb != "-" ? (
              <>
                <a
                  href={datas?.fb}
                  target="_blank"
                  className="flex items-center flex-1 px-1"
                >
                  <img
                    src={require("../../images/s02.png")}
                    className="w-10 h-10 mr-1 md:mr-4"
                  />{" "}
                  Facebook
                </a>
              </>
            ) : null}
            {datas?.lineurl != "-" ? (
              <>
                <a
                  href={datas?.lineurl}
                  target="_blank"
                  className="flex my-2 items-center flex-1 px-1"
                >
                  <img
                    src={require("../../images/s03.png")}
                    className="w-10 h-10  mr-1 md:mr-4"
                  />{" "}
                  {datas?.lineurl}
                </a>
              </>
            ) : null}
            {datas?.phone !== "-" ? (
              <>
                <Link to={""} className="flex items-center flex-1 px-1">
                  <img
                    src={require("../../images/s05.png")}
                    className="w-10 h-10 mr-1 md:mr-2"
                  />{" "}
                  {datas?.phone}
                </Link>
              </>
            ) : (
              <></>
            )}
          </div>
          {datas?.imgs?.slice(1, datas.imgs.length).map((pic, idx) => (
            <div key={`img_${idx}`}>
              <img
                src={`${laborSalepageFileUrl}${pic.photo}`}
                className={`w-full h-[${windowSize[0] * 0.5}px] object-cover`}
              />
            </div>
          ))}
          {/*           {datas.imgs.map((pic, index) => (
            <div key={`img_${index}`} className="rounded-lg overflow-hidden">
              <img
                src={`${laborSalepageFileUrl}${pic.photo}`}
                className={`w-full h-[${windowSize[0] * 0.5}px] object-cover`}
              />
            </div>
          ))} */}
          {/* ---------  */}
        </div>
      </div>
    </>
  );
};

export default PreviewOneSalepage;
